$(function(){

    const
        breakp    = '(min-width: 600px)', // ブレークポイント
        is        = new isMode(breakp),
        isSP      = new isMode('(max-width: 375px)'), // スマホのブレークポイント
        isTab     = new isMode('(max-width: 600px)'), // タブレットのブレークポイント
        $window   = $(window),
        $document = $(document),
        $home     = $('#home'),
        $body     = $('body'),
        $pagetop  = $('#pagetop'),
        $header   = $('#header'),
        $globalnavi = $('#globalnavi').find('.menu');

        // is クラスのプロパティ
        // is.Mobile UAがスマホか判定
        // is.Prod   モードが'product'か判定
        // is.IE     UAがIEまたはEdgeか判定
        // is.Breakp ブレークポイントより大きいか判定

// ページ表示時にふぁっと表示させる
        $('body').fadeIn(1000); //1秒かけてフェードイン！

// windowリサイズ時のJSリロード
        var timer = 0;
        var currentWidth = window.innerWidth;
        $(window).resize(function(){
            if (currentWidth == window.innerWidth) {
                return;
            }
            if (timer > 0) {
                clearTimeout(timer);
            }
     
            timer = setTimeout(function () {
                location.reload();
            }, 200);
            
        });


    /* Slick */
    $('#slides.slide-on').slick(
        {
            centerMode: true,
            centerPadding: '0px',
            cssEase: 'ease-in' ,
            variableWidth: false,
            autoplay: true,
            arrows: false,
            dots: true,
            fade: true,
            infinite: true,
            speed: 300,
            autoplaySpeed: 10000,
            zIndex: 1000
        }
    );
    /* Slick */
    $('.slide-about').slick(
        {
            centerMode: true,
            centerPadding: '0px',
            cssEase: 'ease-in' ,
            variableWidth: false,
            autoplay: true,
            arrows: false,
            dots: true,
            fade: false,
            infinite: true,
            speed: 300,
            autoplaySpeed: 10000,
            zIndex: 1000
        }
    );


// -----------------------------------------------------------------------------------

    /**
     * 便利ツール
     */

    // ScrollTop
    $('#pagetop').stop().on('click', function(e) {
        e.preventDefault();
        $('body, html').animate({scrollTop:0}, 500);
    });


    // telをモバイル以外で非活性化
    if (!is.Mobile) {
        $('a[href^="tel:"]').on('click', (e) => {
            e.preventDefault();
        });
    };


// -----------------------------------------------------------------------------------

    /**
     * 各種イベント発生時に実行したい関数
     */

    // ブレークポイントの変化で実行したいものをここに書く
    let BreakPointEvents = () => {
        $(' #header,svg#hamburger,.btn-area-header,a,menu-btn,.global-nav,.menu li,.menu-item-has-children,.overlay').removeClass('active');

        $('body, #main, #header,.btn-area-header,menu-btn,.global-nav,.menu li,.menu-item-has-children,.overlay').removeAttr('style');

        getBodyHeight();
    };

    // スマホグロナビスクロール時のイベント
    // $(".global-nav").addEventListener('scroll' , function(e){
    //     e.stopProoagation();
    // });



    // スクロールイベント時に実行したいものをここに書く
    let y = 0, x = 0;
    let ticking = false, scrollstat = 0, scrolling;
    let ScrollEvents = () => {
        y = window.pageYOffset; // 垂直方向
        if (!ticking) {
            requestAnimationFrame(function() {
                ticking = false;
                // 処理
                scrolling = y;

                // header のbackground をスクロール方向でスライドインアウトさせる
                // let bgPosition = $('#header').height();
                // console.log(bgPosition);

                if (scrollstat < 0 ){

                    // $('#header').css('background-position', 'center top');

                    // if (!isSP) $('.sub-menu li').css('background-color', 'rgba(255,255,255,.8)');

                } else if (scrollstat < scrolling) { // 下にスクロールの時
                    // $('#header').css('background-position', 'center top -'+ bgPosition + 'px');

                    if (!isSP) $('.sub-menu li').css('background-color', 'rgba(255,255,255,0)');

                } else { // 上にスクロールの時

                    // $('#header').css('background-position', 'center top');

                    if (!isSP) $('.sub-menu li').css('background-color', 'rgba(255,255,255,.8)');

                }

                // if (!isSP){
                // if (is.Breakp) {
                // トップへ戻るボタンの位置をスクロール方向で決定
                let objPos = $('#copy-area').offset(); // 固定目標object取得
                let windowHeight = $(window).height(); // windowの高さ取得
                let objPosTop = objPos.top; // 固定目標のtop位置取得
                let targetPos = objPosTop - windowHeight; // scrollの代わりに固定目標top位置からウインドウの高さを引く

                if (y > targetPos ){ // スクロールが固定目標top位置以上
                    $('#pagetop').css({
                        'position':'absolute',
                        'bottom': -objPos,
                    });
                } else { // スクロールが固定目標top位置以下
                    $('#pagetop').css({
                        'position':'fixed',
                        'bottom': 0,
                    });
                }
            // }

                scrollstat = scrolling;
            });
            ticking = true;
        }
    };
    document.addEventListener('scroll', ScrollEvents, {passive: true});

// -----------------------------------------------------------------------------------
    /**
     * スマホ表示 グローバールメニューの開閉
     */
    // init
    if (!is.Breakp) {
        if (! $('.overlay').length ) $body.append('<div class="overlay">');
    };
    if (!is.Breakp) {
    // // 開く
 
        $(".menu-btn").on('click' , function(e) {
            $(".global-nav").animate(
                {height:"toggle",opacity:"toggle"},
                "slow"
            );
            var e = $(this);
            $(e).toggleClass('active');
            $('#hamburger').toggleClass('active');
            $('.btn-area-header').toggleClass('active');
            $('.overlay').toggleClass('active');
        })

    // 閉じる
        $document.on('click', '.sub-menu>li>a , .overlay', () => {
            // $('.global-nav').css({'opacity': '0','transform':' rotateY(00deg)','animation':'draweropen .25s ease-out 0s'});
            $(".global-nav").animate(
                {height:"toggle",opacity:"toggle"},
                "slow"
            );
            $('.overlay').toggleClass('active');
        })
    };

    /**
     * スマホ表示 グローバール　サブメニューの開閉
     */
    if (isSP) {
        $('.menu-item-has-children').on('click', function() {
            $("ul",this).slideToggle();
            $(this).toggleClass('active');
        });
    }

    if(is.Breakp) {
        $('button.select-category').on('click' , function() {
            var target = $(this).attr('id');
            $('ul.detail-area').children('li').css({  display : 'none'});
            $('ul.detail-area').children('.'+target).css({ display : 'flex' });
        })
    }
//-----------------------------------------------------------------------------------

    /**
     * ブレークポイントの変更を検知
     */
    const mediaQueryList = matchMedia(breakp);
    mediaQueryList.addListener( BreakPointEvents );

// -----------------------------------------------------------------------------------
    /**
     * #mainのmin-height設定
     */

    var fh = $('#footer');
    var footerHeight = fh.outerHeight();
    var mainMinHeight = window.innerHeight - footerHeight;
    $('#main').css('min-height',mainMinHeight);



// 
}); // End of jQuery

// -----------------------------------------------------------------------------------

/**
 * アイキャッチ画像の、PC・SP時の差し替え
 * jQuery外処理
 */
    document.addEventListener('DOMContentLoaded', function () {
    // @media screen and (max-width: 600px) と同じ
    var mql = window.matchMedia('(max-width: 600px)');

    // function checkBreakPoint(mql) {
    //     if (mql.matches) {
    //         $('.eyeimg').each(function(){
    //             // console.log(this);
    //             $(this).attr('src', function(i, s) {
    //                 var src = s.replace(/\.jpg/g, '-sp.jpg');
    //                 return src;
    //             });
    //         });
    //     } else {
    //         $('.eyeimg').each(function(){
    //             $(this).attr('src', function(i, s) {
    //                 var src = s.replace(/-sp\.jpg/g, '.jpg');
    //                 return src;
    //             });
    //         });
    //     }
    // }

    // ブレイクポイントの瞬間に発火
    mql.addListener(checkBreakPoint);

    // 初回チェック
    checkBreakPoint(mql);
});
