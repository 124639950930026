/**
 * 初期値を取得するクラス
 */
class isMode {
    /**
     * @constructor
     * @param  {string} breakpoint ブレークポイント値 e.g.'(min-width: 600px)'
     */
    constructor(breakpoint) {
        /** @type {String} UserAgentの取得 */
        this.UA = navigator.userAgent.toLowerCase();
        /** @type {Boolean} スマートフォン判定 */
        this.Mobile = /iphone/.test(this.UA)||/android(.+)?mobile/.test(this.UA);
        /** @type {Boolean} productモード判定 */
        this.Prod = document.body.dataset.isProd;
        /** @type {Boolean} IE判定 */
        this.IE = /trident/.test(this.UA)||/edge/.test(this.UA);
        /** @type {Boolean} ブレークポイント判定 */
        this.bp = window.matchMedia(breakpoint);
        //this.bp.addListener(this.bpmatches);
        //this.resize = (bp) => bp.matches;
        //this.breakp = this.bp.matches;
    }
    /**
     * 現在のウィンドウサイズがブレークポイント範囲外か判定
     * get 構文は、オブジェクトのプロパティを、プロパティが参照された時に関数が呼び出されるように結びつける
     * @return {Boolean} PCサイズなら true
     */
    get Breakp() {
        return this.bp.matches;
    }
    /**
     * テスト用
     * @return {String} 各プロパティを出力
     */
    // log() {
    //     console.log( this.UA );
    //     console.log( 'Mobile:', this.Mobile);
    //     console.log( 'Product:', this.Prod );
    //     console.log( 'IE:', this.IE);
    //     console.log( 'breakpoint:', this.bp);
    // }
};

